
/* -------------------------------------------------------------------------- */
/*                               Settings Panel                               */
/* -------------------------------------------------------------------------- */

.btn-group-navbar-style{
  .btn-check{
    + .btn-navbar-style{
      box-shadow: none;
      padding: 0;
      margin-bottom: 1rem;
      text-align: left;
      .img-prototype{
        border: 3px solid var(--#{$variable-prefix}200);
        border-radius: $border-radius-lg;
        transition: border 0.2s ease;
        margin-bottom: 0.5rem;
        background-color: var(--#{$variable-prefix}100);
      }
      .label-text{
        position: relative;
        padding-left: 1.5rem;
        &:after{
          position: absolute;
          content: '';
          left: 0;
          width: 1rem;
          height: 1rem;
          border: 1px solid var(--#{$variable-prefix}500);
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          transition: border 0.2s ease;
        }
      }
    }
    &:checked:not([disabled]) + .btn-navbar-style{
      .img-prototype{
        border-color: var(--#{$variable-prefix}success);
      }
      .label-text::after{
        border: 5px solid var(--#{$variable-prefix}success);
      }
    }
    &:disabled{
      + .btn-navbar-style{
        opacity: 0.5;
      }
    }
  }
  .hover-overlay {
    position: relative;
    overflow: hidden;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      z-index: 1;
      transition: $transition-base;
    }
    @include hover-focus {
        &:after {
          background: rgba($black, 0.2);
        }
    }
  }
}


.setting-toggle {
  position: fixed;
  top: 50%;
  right: 0;
  text-decoration: none;
  z-index: 1016;
  transform: rotate(-90deg) translate3d(-25px, 39px, 0);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: -83px;
  box-shadow: var(--#{$variable-prefix}setting-toggle-shadow);

  // .position-fixed.top-50.end-0.card.setting-toggle.text-decoration-none

  @include media-breakpoint-down(sm) {
    transform: rotate(-90deg);
    outline: none;
    .bg-soft-primary {
      background-color: transparent !important;
    }
    small {
      display: none;
    }
  }

  &:hover{
    text-decoration: none !important;
  }
}